<template>
<div class="contains-loader">
  <div class="loader" v-if="waiting_response">
    <v-progress-circular class="text-loader" :size="100" :width="10" color="#e73188" indeterminate></v-progress-circular>
  </div>
</div>
<v-card class="mx-auto card-center" width="400" prepend-icon="mdi-home">
  <img src="@/assets/logo.svg" class="logo-login">
    <template v-slot:title>Connexion</template>
    <v-card-text>
        <v-sheet width="300" class="mx-auto">
            <v-form fast-fail @submit.prevent>
                <!-- <span v-if="error_found" class="error-message" >Identifiant ou mot de passe incorrect!</span> -->
                <span v-if="error_server" class="error-message" >Erreur serveur, veuillez réessayer ultérieurement.</span>

                <!-- <v-text-field :disabled="waiting_response" type="email" v-model="email" label="Adresse mail" placeholder="jhon.doe@destia.fr"></v-text-field>
                <v-text-field :disabled="waiting_response" type="password" v-model="password" label="Mot de passe"></v-text-field>
                <v-btn :disabled="waiting_response" type="submit" block class="mt-2" @click="login">Connexion</v-btn> -->
                <v-btn :disabled="waiting_response" :href="uri_login_azure" block class="mt-2" >Connexion Destia microsoft</v-btn>
                
            </v-form>
        </v-sheet>
    </v-card-text>
</v-card>

   
</template>

<style scoped>
.contains-loader{
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90vh;
}
.text-loader{
    top:150px
}
.loader{
    text-align: center;
    background-color: rgba(0, 0, 0, 0.500 );
    width: 600px;
    height: 440px;
    margin-top: -20px;
    z-index: 1000;
}
.card-center{
  margin-top: 10vh;
}
.logo-login{
    width: 60%;
    display: block;
    margin: auto;
}
.error-message{
    color:red;
}

#btn-router{
    text-decoration: none;
    color:black
}

</style>

<script>
import router from '@/router'
import store from '@/store'
import { useTheme } from 'vuetify'

export default {
  name: 'Login',
  data(){
    return{
      waiting_response:false,
      uri_login_azure: null,
      error_server: false
    }
  },
  //(Vue) mounted = au moment de la création de la vue pour vérifier que les infos ne sont pas déjà dans le local storage (sinon pax déjà connectée)
  mounted(){
    const theme = useTheme()
    let theme_bool = document.cookie.match(/theme_dark=(\w+)/)
    this.error_server = this.$route.query.error
    if(theme_bool != undefined){
      store.state.theme_dark = theme_bool[1]
      let isTrueSet = store.state.theme_dark == "true"
      // theme.global.current.value.dark = isTrueSet
      // console.log("->"+store.state.theme_dark)
      theme.global.name.value = isTrueSet? 'myCustomDarkTheme':'myCustomLightTheme'
    }
    //si le token, l'userId et l'admin de stockés dans le local storage :
    if(sessionStorage.getItem('token') && sessionStorage.getItem('email') && sessionStorage.getItem('fullname') && sessionStorage.getItem('expire')){
      //on stock ces infos dans le store (Vue)
      store.state.token = sessionStorage.getItem('token');
      store.state.email = sessionStorage.getItem('email');
      store.state.expire = sessionStorage.getItem('expire');
      store.state.fullname = sessionStorage.getItem('fullname');
      //et on renvoie à la page d'accueil
      router.push("/home");
    }else{
      const options = {
            method: 'GET'
      };
      fetch(this.api_chatbot + this.route_get_uri_login, options)
      .then(res=>{
        
        return res.json()
      })
      .then(data =>{
          this.uri_login_azure = data["auth_uri"]
        
      })
    }
  }
}
</script>
  