/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { createVuetify, ThemeDefinition } from 'vuetify'
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';


const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#ffffff',
    primary: '#27348b',
    'primary-darken-1': '#181f53',
    secondary: '#e73188',
    'secondary-darken-1': '#96235b',
    error: '#B00020',
    info: '#009dd9',
    success: '#4fae47',
    warning: '#eb6608',
  },
}

const myCustomDarkTheme = {
  dark: true,
  colors: {
    background: '#202020',
    surface: '#202020',
    primary: '#27348b',
    'primary-darken-1': '#181f53',
    secondary: '#e73188',
    'secondary-darken-1': '#96235b',
    error: '#B00020',
    info: '#009dd9',
    success: '#4fae47',
    warning: '#eb6608',
  },
}

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomDarkTheme',
    themes: {
      myCustomDarkTheme,
      myCustomLightTheme
    },
  },
  components,
  directives,
})