// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'
// Store
import store from './store'

// import vuetify from '@/plugins/vuetify'

const app = createApp(App).use(store)
// const app = createApp(App).use(store).use(vuetify)

app.config.globalProperties.api_chatbot = process.env.VUE_APP_API_CHATBOT
// app.config.globalProperties.api_chatbot = "http://localhost:8000/"
//all routes
app.config.globalProperties.route_send_message = "send-message/"
app.config.globalProperties.route_history_chat = "history-chat/"
app.config.globalProperties.route_verifier_signup = "verified-login/"
app.config.globalProperties.route_get_uri_login = "get-uri-login/"


registerPlugins(app)

app.mount('#app')
