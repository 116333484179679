<template>
  
  <v-navigation-drawer color="secondary" v-model="sidebar" app>
    <v-list>
      <v-toolbar-items>
      <v-btn flat>
        <v-switch @click="toggleTheme" class="btn-nav" v-model="switchModel"></v-switch>
      </v-btn>
      <v-btn flat @click="logout">
        <p class="btn-nav"><v-icon icon="mdi-logout"></v-icon></p>
      </v-btn>
    </v-toolbar-items>

    </v-list>
  </v-navigation-drawer>

  <v-app-bar color="secondary">
  <v-icon  v-if="displayMenu" class="menu-icon" icon="mdi-menu" @click="sidebar = !sidebar"></v-icon>
  <router-link class="btn-nav" to="/home"><img src="../assets/logo_navbar.png" class="logo"/></router-link>
  <!-- <router-link class="btn-nav" to="/home"><img src="../assets/logo_navbar.jpg" class="logo-old"/></router-link> -->
  <v-app-bar-title>{{ page }}</v-app-bar-title>
  
  <v-toolbar-items v-if="!displayMenu">
      <v-btn flat @click="logout">
        <!-- <p class="btn-nav"><v-icon icon="mdi-logout"></v-icon></p> -->
        <p class="btn-nav">déconnexion</p>
      </v-btn>
      <v-btn flat>
        <v-switch @click="toggleTheme" class="btn-nav" v-model="switchModel"  :label="textSwitch"></v-switch>
      </v-btn>
    </v-toolbar-items>
  <router-view></router-view>
</v-app-bar>

  
</template>
    
<style>
.menu-icon{
  margin-right: 20px;
  margin-left: 10px;
}
.logo-old{
    background-color: azure;
    margin-left: 10px;
    height: 60px;

}
.logo{
    margin-left: 10px;
    height: 50px;

}
.btn-nav{
  color:white;
  text-decoration: None;
  height:64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch-button{
  height: 64px;
}
</style>
    
<script>

import router from '@/router'
import store from '@/store'
import { useTheme } from 'vuetify'



export default {
    name: "NavbarGlobal",
    props:{
        page: String
    },
    setup () {
    const theme = useTheme()
      return {
        theme,
        toggleTheme: () => {
          store.state.theme_dark = !store.state.theme_dark
          sessionStorage.setItem("theme_dark",store.state.theme_dark)
          let d = new Date();
          d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();
          document.cookie = "theme_dark="+store.state.theme_dark+";"+expires
          // theme.global.current.value.dark = store.state.theme_dark
          theme.global.name.value = store.state.theme_dark ? 'myCustomDarkTheme':'myCustomLightTheme'
        }
      }
    },
    data(){
      return{
        sidebar: false,
        displayMenu:false,
        screenSize:window.innerWidth,
        limit_screen_width:600,
        switchModel:true,
        // theme:null
      }
    },
    computed:{
      textSwitch(){
        if (this.switchModel){
          return "White theme"
        }else{
          return "Dark theme"
        }
      }
    },
    methods:{
      logout: function(){
        store.state.email = null;
        store.state.fullname = null;
        store.state.expire=null;
        store.state.token = null;
        sessionStorage.clear();
        router.push("/");
      },
      onResize() {
        this.screenSize = window.innerWidth
        if(this.screenSize >= this.limit_screen_width){
          this.displayMenu= false
          this.sidebar= false
        }else{
          this.displayMenu= true
        }
      }
    },
    mounted() {
      const theme = useTheme()
      // theme.global.current.value.dark = store.state.theme_dark
      if(sessionStorage.getItem("theme_dark")){
        let isTrueSet = sessionStorage.getItem("theme_dark") == "true"
        store.state.theme_dark = isTrueSet
      }else{
        let theme_bool = document.cookie.match(/theme_dark=(\w+)/)
        if(theme_bool != undefined){
          let isTrueSet = theme_bool[1] == "true"
          store.state.theme_dark = isTrueSet
          sessionStorage.setItem("theme_dark",isTrueSet)
        }
      }
      theme.global.name.value = store.state.theme_dark ?'myCustomDarkTheme': 'myCustomLightTheme' 
      this.switchModel = !store.state.theme_dark
      this.onResize()
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },

}

</script>