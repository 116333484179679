<template>
<span v-html="start_text"></span>
<ListInBox v-if="list.lenght != 0" :list_items="list" :number="isNumber"></ListInBox>
<span v-html="end_text"></span>
</template>

<style>
.sur-expo{
    color: #e73188;
    text-decoration: none;
    font-weight: bold;
    
}
.link-perso{
    color: #27348b;

}
</style>

<script>
import ListInBox from './ListInBox.vue'


export default {
    name: "ChatBox",
    props: {
        content: ""
    },
    data() {
        return {
            start_text: "",
            end_text:"",
            list:[],
            isNumber:null,
            pattern_bold:[
                "RSE","Responsabilité Sociale et Environnementale","développement durable","Développement durable"," formations"," formation",
                "entreprise à mission","Entreprise à mission","Responsabilité Sociétale des Entreprises","raison d\'être",
                "empreinte environnementale","Destia","Care","Société à mission","société à mission","Responsabilité Sociale des Entreprises",
                "maintien à domicile","Maintien à domicile","garde d\'enfants","Garde d\'enfants","garde d\'enfant","Garde d\'enfant",
                "Ecoute","Écoute","écoute","Engagement ","Engagements "," engagement ", " engagements ", "\'engagement", "\'engagements","Enthousiasme","enthousiasme",
                "138 agences","4 engagements","quatre engagements"]
        };
    },
    methods: {
        convert_url(content, url){
            let start = content.split(url)[0]
            let end = content.split(url)[1]
            let output =""
            if(!url.includes("http")){
                output = start + "<a  class=\"link-perso\" href=\"http://"+ url +"\" target=\"_blank\">"+ url + "</a>" + end
            }else{
                output = start + "<a  class=\"link-perso\" href=\""+ url +"\" target=\"_blank\">"+ url + "</a>" + end
            }
            return output
        },
        convert_tel(content, tel){
            let splited = content.split(tel)
            let start = splited[0]
            let end = content.split(start + tel)[1]
            start = content.split(tel + end)[0]
            
            let output = start + "<a  class=\"link-perso\" href=\"tel:"+ tel.split(" ").join("") +"\">"+ tel + "</a>" + end
            
            return output
        },
        convert_mail(content,mail){
            let splited = content.split(mail)
            let start = splited[0]
            let end = content.split(start + mail)[1]
            start = content.split(mail + end)[0]
            let output = start + "<a  class=\"link-perso\" href=\"mailto:"+ mail +"\">"+ mail + "</a>" + end
            return output
        },
        add_return_line(content){
            content = content.split("\n\n").join("<br><br>")
            content = content.split("\n").join("<br>")
            if(content.includes(". ")){
                let splited = content.split(/\. [A-Z]/)
                let new_content = []
                splited.forEach(element => {
                    new_content.push(content.indexOf(element) != 0?content[content.indexOf(element) - 1] + element:"" + element)
                });
                splited = new_content
                return splited.join(".<br>").split(".\n\n").join(".<br><br>")
            }else{
                return content
            }
            

        },
        add_bold(content,pattern){
            let splited = content.split(pattern)
            for(let i = 1; i < splited.length; i++){
                let start = splited[i-1]
                let end = ""
                for(let j = i; j < splited.length;j++){
                    
                    if (j == splited.length - 1){
                        end += splited[j]
                    }else{
                        end += splited[j] + pattern
                    }
                }
                start = content.split(pattern + end)[0]
                content = start + "<span class=\"sur-expo\" >" + pattern + "</span>" + end
            }
            return content
        },
        generateTemplate() {
            
            let final_content = this.content
            // mise en place des url
            if(final_content.includes("www.")){
                // boucle pour toutes les urls
                let list_url = final_content.split("www.")
                for(let i = 1; i < list_url.length; i++){
                    let url =  "www." + list_url[i].split(" ")[0]
                    if(url.includes("\n")){
                        url = url.split("\n")[0]
                    }
                    while(url.length > 1 && (url[url.length -1]=="." || url[url.length -1]==")" || url[url.length -1]=="!" || url[url.length -1]==":" || url[url.length -1]==",")){
                        url = url.substring(0, url.length -1)
                    }

                    final_content = this.convert_url(final_content,url)
                }

            }
            // mise en place des lien tel
            // let match = final_content.replace("+33","0").match(/0[1-9](\s{0,1}[0-9]{2}){4}/)
            let all_match = Array.from(final_content.replace("+33","0").matchAll(/0[1-9](\s{0,1}[0-9]{2}){4}/g))
            if(all_match.length >= 1){
                all_match.forEach(element => {
                    let tel = element[0]
                    final_content = this.convert_tel(final_content,tel)
                })
            }
            const emailRegex = /[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)/g;

            all_match = Array.from(final_content.matchAll(emailRegex))
            if(all_match.length >= 1){
                all_match.forEach(element => {
                    let mail = element[0]
                    final_content = this.convert_mail(final_content,mail)
                })
            }
            // Mise en gras des elements
            this.pattern_bold.forEach(element =>{
                if (final_content.includes(element)){
                    final_content = this.add_bold(final_content,element)
                }
            })
            if (final_content.includes("1. ")) {
                const start_text = final_content.split("1. ")[0] 
                const splited = final_content.split("1. ")[1]
                const all_list = splited.split(/\s+\d+\.\s+/)
                // const all_list = splited.split("\n\n")
                let end_text = all_list[all_list.length - 1].split("\n\n")[1]
                all_list[all_list.length - 1] = all_list[all_list.length - 1].split("\n\n")[0]
                this.start_text=this.add_return_line(start_text)
                if (end_text != undefined){
                    this.end_text = this.add_return_line(end_text)
                }else{
                    end_text = all_list[all_list.length - 1].split("\n")[1]
                    all_list[all_list.length - 1] = all_list[all_list.length - 1].split("\n")[0]
                    if (end_text != undefined){
                        this.end_text = this.add_return_line(end_text)
                    }
                }
                this.isNumber = true
                this.list = all_list
            }
            else if (final_content.includes(":\n\n- ")) {
                const start_text=final_content.split(":\n\n- ")[0]

                const splited = final_content.split(":\n\n- ")[1];
                const all_list = splited.split("\n- ")
                const end_text = all_list[all_list.length - 1].split("\n\n")[1]
                all_list[all_list.length - 1] = all_list[all_list.length - 1].split("\n\n")[0]

                this.start_text= this.add_return_line(start_text + " :")
                if (end_text != undefined){
                    this.end_text = this.add_return_line(end_text)
                }
                this.isNumber = false
                this.list = all_list
            }else if (final_content.includes(":\n- ")) {
                const start_text=final_content.split(":\n- ")[0]

                const splited = final_content.split(":\n- ")[1];
                const all_list = splited.split("\n- ")
                const end_text = all_list[all_list.length - 1].split("\n")[1]
                all_list[all_list.length - 1] = all_list[all_list.length - 1].split("\n")[0]

                this.start_text= this.add_return_line(start_text + " :")
                if (end_text != undefined){
                    this.end_text = this.add_return_line(end_text)
                }
                this.isNumber = false
                this.list = all_list
            }else{
                this.start_text = this.add_return_line(final_content)
            }
        }
    },
    mounted() {
        this.generateTemplate();
    },
    components: { ListInBox }
}

</script>