<template>

    <v-list tag='ol' v-if="number && number != null" class="list-design" >
        <template v-for="(item, index) in list_items">
            
            <v-list-item tag='li' class="list-item-design">
            {{ index+1 }}. <span v-html="item"></span>
            </v-list-item>
        </template>
    </v-list>

    <v-list tag='ul' v-if="!number && number != null"  class="list-design" >
        <template v-for="(item, index) in list_items">
            <v-list-item tag='li' class="list-item-design">
            - <span v-html="item"></span>
            </v-list-item>
        </template>
    </v-list>
</template>


<style>
.list-design{
    margin-left:5%;
    margin-bottom: 30px;
}
.list-design > .list-item-design{
    margin-top: 4vh;
}
.list-design > .list-item-design:last-child{
    margin-bottom: 4vh;
}
</style>

<script>


export default {
    name: "ListInBox",
    props: {
        list_items: "",
        number:null
    },
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
        // console.log(this.list)
    }
}

</script>