<template>

    <h1 class="text-loader">Erreur 404 vous allez être redirigé</h1>
    <div class="loader">
        
        <v-progress-circular class="loader" :size="100" :width="10" color="#e73188" indeterminate></v-progress-circular>
    </div>
</template>


<style scoped>
.text-loader{
    text-align: center;
}
.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 60vh;
}
</style>

<script>
import router from '@/router';

export default {
    name : "NotFound",
    mounted(){
        // laisser la page quelque seconde puis retourne sur la page de login
        setTimeout(function(){
            router.push("/") 
        },1200) 
    }
}

</script>
      