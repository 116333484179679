<template>

<v-app app>
    
  <v-container class="fill-height dialog-box" >
    <NavbarGlobal page="Dest-IA Chatbot"/>
    <v-row class="fill-height pb-14 all-chat" align="end">
      <v-col>
        
        <div v-for="(item, index) in chat" :key="index" :class="['d-flex flex-row align-center my-2 box-chat', item.role == 'user' ? 'justify-end': null]">
          <span v-if="item.role == 'user'" class="blue--text mr-3">{{ item.content }}</span>
          <!-- <span v-if="item.role == 'user'" class="blue--text mr-3"><ChatBox :content="item.content"></ChatBox></span> -->
          
          <v-avatar  v-if="item.role != 'user'" :color="item.role == 'user' ? 'primary': 'gray'" size="60">
             <v-img v-if="item.role != 'user'" src="../assets/logo_chatbot.png"></v-img>
          </v-avatar>
          <v-avatar v-if="item.role == 'user'" :color="item.role == 'user' ? 'primary': 'gray'" size="40">
             <span v-if="item.role == 'user'" class="white--text">{{ icon_text }}</span>
          </v-avatar>
          <div v-if="item.role != 'user'" class="blue--text ml-3"><ChatBox :content="item.content"></ChatBox></div>
          <!-- <span v-if="item.role != 'user'" class="blue--text ml-3">{{ item.content }}</span> -->
        </div>
        <span v-if="error" class="error-message">Une erreur est survenue OU la taille du texte est trop grand... veuillez réessayer ultérieurement.</span>
        <span v-if="error_no_msg" class="error-message">Veuillez saisir un message.</span>
      </v-col>
    </v-row>
    
  </v-container>
  <v-container v-if="wait_message">
  <v-row>
    <v-col cols="1">
        <v-progress-circular  indeterminate color="primary"></v-progress-circular>
        
    </v-col>
    <v-col cols="8">
        <p>Génération de la réponse...</p>
    </v-col>
  </v-row>
</v-container>
  <v-footer fixed >
    <v-container class="ma-0 pa-0">
      <v-row no-gutters>
        <v-col>
          <div class="d-flex flex-row align-center">
            <v-text-field ref="bottomEl" :disabled="disabled_input" v-model="msg" placeholder="Posez une question à Dest-IA..." @keypress.enter="send"></v-text-field>

            <v-btn :disabled="disabled_input" icon color="primary" class="ml-4" @click="send">
                <v-tooltip activator="parent" location="top">Envoie du message</v-tooltip>
                <v-icon>mdi-send</v-icon>
            </v-btn>

            <v-btn :disabled="disabled_input" v-bind="deleteprops" icon color="secondary" class="ml-4" @click="deleteConversation">
                <v-tooltip activator="parent" location="top">Supprime tous les messages</v-tooltip>
                <v-icon>mdi-delete</v-icon>
            </v-btn>

           
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</v-app>
</template>


<style>

.nav-bar-title{
    height: 64px;
}
.dialog-box{
    padding-top: 64px !important;

}
.box-chat{
    border-bottom: inset;
    padding-top: 15px;
    padding-bottom: 15px;
    max-width: 95vw;
    overflow-wrap: anywhere;
}
.error-message{
    color: red;
}
.all-chat{
    font-size: 1.3rem;
    /* font-family: 'Raleway', sans-serif; */
    font-family: 'Avenir Book', sans-serif;
    font-weight: normal;
    font-style: normal;
}
</style>

<script>

import NavbarGlobal from "@/components/NavbarGlobal.vue"
import router from '@/router'
import store from '@/store'
import ChatBox from "@/components/ChatBox.vue"
const initial_message = "Bonjour, je suis Dest-IA. Je suis un assistant qui répondra à toutes vos questions sur l'entreprise."

export default {
    name: "DialogBox",
    components: {NavbarGlobal,ChatBox},
    data(){
        return {
            chat:[],
            msg: null,
            userId :null,
            wait_message: false,
            error:false,
            disabled_input:false,
            error_no_msg:false,
            code:null
        }
    },
    computed:{
        icon_text(){
            let splited = this.userId.split(" ")
            let initial = ""
            if(splited.length == 2){
                initial+= splited[0][0]
                initial+= splited[1][0]
                return initial
            }else{
                return "U"
            }
        }
    },
    methods: {
        send(){
            if(this.msg == "" || this.msg == undefined || this.msg == null){
                this.error_no_msg = true
                this.msg = null
                return 0
            }
            this.chat.push(
            {
                role: "user",
                content: this.msg
            })
            let last_remove = null
            if(this.chat.length > 8){
                last_remove = this.chat.shift()
            }

            this.wait_message = true
            this.disabled_input = true
            setTimeout(() => {
                if(this.$refs.bottomEl){
                    this.$refs.bottomEl.scrollIntoView({behavior : "smooth"})
                }
            }, 200);
            this.send_message().then( res =>{
                this.disabled_input = false
                this.wait_message = false
                this.error = false
                this.error_no_msg = false
                this.msg = null
                if(this.chat.length > 8){
                    this.chat.shift()
                }
                if(this.$refs.bottomEl){
                    this.$refs.bottomEl.scrollIntoView({behavior : "smooth"})
                }
            }).catch(err =>{
                this.disabled_input = false
                this.wait_message = false
                this.error_no_msg = false
                this.error = true
                if (last_remove != null){
                    this.chat.unshift(last_remove)
                }
                this.chat.pop()
                if(this.$refs.bottomEl){
                    this.$refs.bottomEl.scrollIntoView({behavior : "smooth"})
                }
            })
            
        },
        send_message(){
            return new Promise((resolve, reject)=>{
                const options = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + store.state.token
                    },
                    body: JSON.stringify({"user":this.userId,"text":this.msg})

                };
                fetch(this.api_chatbot + this.route_send_message,options)
                .then(res =>{
                    if(res.status != 403){
                        return res.json();
                    }else{
                        // Token invalid
                        router.push("/")
                        sessionStorage.clear()
                        store.state.email = null
                        store.state.fullname = null
                        store.state.token = null
                    }
                }).then(data =>{
                    if(!data.error){
                        this.chat.push(data)
                        resolve(true)
                    }
                    reject("error")
                }).catch(err =>{
                    reject("error")
                })
                
            })
            
        },
        get_history(){
            const options = {
                method: 'GET',
                headers: {
                    "Authorization": "Bearer " + store.state.token
                }
            };
            fetch(this.api_chatbot + this.route_history_chat,options)
            .then(res =>{
                if(res.status != 403){
                    if(res.status >= 400){
                        this.error = true
                        this.error_no_msg = false
                    }    

                    return res.json();
                    
                }else{
                    router.push("/")
                    sessionStorage.clear()
                    store.state.email = null
                    store.state.fullname = null
                    store.state.token = null
                }
            }).then(data =>{
            // filtre des role system
            const tmp_data = []
            for(let i = 0; i < data.length ; i++){
                if(data[i].role != "system"){
                    tmp_data.push(data[i])
                }
            }
            return tmp_data
            })
            .then(data => {
                // verif si aucun historique
                if(data != null){
                    
                    if(data.length == 0){
                        this.chat.push({"role":"assistant","content":initial_message})
                    }else{
                        this.chat = data
                    }

                }
            }).catch(err=>{
                this.error = true
                this.error_no_msg = false
            })
        },
        deleteConversation(){
            const options = {
                method: 'DELETE',
                headers: {
                    "Authorization": "Bearer " + store.state.token
                }

            };
            fetch(this.api_chatbot + this.route_history_chat,options)
            .then(res =>{
                if(res.status != 403){
                    return res.json();
                }else{
                    router.push("/")
                    sessionStorage.clear()
                    store.state.email = null
                    store.state.fullname = null
                    store.state.token = null
                }
            }).then(data =>{
                if(!data.error){
                    this.chat= []
                    this.chat.push({"role":"assistant","content":initial_message})
                    this.error = false
                    this.error_no_msg = false
                }else{
                    this.error = true
                    this.error_no_msg = false
                }
            }).catch(err=>{
                this.error= true
                this.error_no_msg = false
            })
        }
    },
    mounted(){
        if(store.state.token == null){
            if(sessionStorage.getItem('token') && sessionStorage.getItem('email') && sessionStorage.getItem('fullname') && sessionStorage.getItem('expire')){
                //on stock ces infos dans le store (Vue)
                store.state.token = sessionStorage.getItem('token');
                store.state.expire = sessionStorage.getItem('expire');
                store.state.email = sessionStorage.getItem('email');
                store.state.fullname = sessionStorage.getItem('fullname');
                let date_expiration = new Date(store.state.expire * 1000)
                let date_now = new Date()
                if( date_now > date_expiration){
                    store.state.token = null;
                    store.state.expire = null;
                    store.state.email = null;
                    store.state.fullname = null;
                    sessionStorage.clear();
                    router.push("/")
                }
                this.get_history()
                this.userId = store.state.fullname
                setTimeout(() => {
                    if(this.$refs.bottomEl){
                        this.$refs.bottomEl.scrollIntoView({behavior : "smooth"})
                    }
                }, 500);
            }else{
                // Redirection de azure après connexion
                this.code = this.$route.query.code
                if (this.code == null){
                    router.push("/")
                }else{
                    const options = {
                        method: 'GET',
                    };
                    fetch(this.api_chatbot + this.route_verifier_signup + "?code=" + this.code ,options)
                    .then(res =>{
                        return res.json()
                    })
                    .then(data => {
                        if(data.error){
                            router.push("/?error=true")
                        }else{
                            store.state.token = data.token;
                            store.state.email = data.email;
                            store.state.expire = data.expire;
                            store.state.fullname = data.fullname;

                            sessionStorage.setItem("token",data.token);
                            sessionStorage.setItem("email",data.email);
                            sessionStorage.setItem("expire",data.expire);
                            sessionStorage.setItem("fullname",data.fullname);
                            let date_expiration = new Date(store.state.expire * 1000)
                            let date_now = new Date()
                            if( date_now > date_expiration){
                                store.state.token = null;
                                store.state.expire = null;
                                store.state.email = null;
                                store.state.fullname = null;
                                sessionStorage.clear();
                                router.push("/")
                            }
                            this.get_history()
                            this.userId = store.state.fullname
                            setTimeout(() => {
                                if(this.$refs.bottomEl){
                                    this.$refs.bottomEl.scrollIntoView({behavior : "smooth"})
                                }
                            }, 500);
                            router.replace("/home")
                        }
                        
                    }).catch(err=>{
                        router.push("/?error=true")
                    })
                }
            }
        }else{
            store.state.token = sessionStorage.getItem('token');
            store.state.expire = sessionStorage.getItem('expire');
            store.state.email = sessionStorage.getItem('email');
            store.state.fullname = sessionStorage.getItem('fullname');
            let date_expiration = new Date(store.state.expire * 1000)
            let date_now = new Date()
            if( date_now > date_expiration){
                store.state.token = null;
                store.state.expire = null;
                store.state.email = null;
                store.state.fullname = null;
                sessionStorage.clear();
                router.push("/")
            }
            this.get_history()
            this.userId = store.state.fullname
            setTimeout(() => {
                if(this.$refs.bottomEl){
                    this.$refs.bottomEl.scrollIntoView({behavior : "smooth"})
                }
            }, 500);
        }
    }

}

</script>